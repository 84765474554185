import React from 'react';
import { useTranslation } from "react-i18next";

const Service = ({title, content}) => {

    const { t } = useTranslation();

    return (
        <div className="service-item">
            <h3>{t(title)}</h3>
            <p>{t(content)}</p>
        </div>
    );
};

export default Service;