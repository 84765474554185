import React from 'react';
import {Link} from "react-router-dom";
import errorThumb from '../../assets/images/404.png';
import { useTranslation } from "react-i18next";

const ErrorPage = () => {

    const { t } = useTranslation(); 

    return (
        <div className="content-body section w-100 h-100">
            <div className="section-wrap section error-404-wrap d-flex align-items-center bg-grey">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="error-404-content ">
                            <h1>OOPS!</h1>
                            <h3>{t("error_title")}</h3>
                            <Link to={`${process.env.PUBLIC_URL + '/'}`}>{t("error_back")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;