import React from 'react';
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";

const PortfolioDetailsContent = ({title, slug, category, description, meta}) => {
    const { t } = useTranslation();
    //console.log(meta);
    return (
        <div className="portfolio-details-content">
            <Link to={`/portfolio/${slug}`} className="category">{t(category)}</Link>
            <h1 className="title">{t(title)}</h1>
            {description.map((description, index) => (
                        <h5 key={index}>{t(description)}</h5>
            ))}
            <ul className="project-info">
                <li><span>{meta?.client.length > 1 ? "Clients:" : "Client:"}</span>
                    {meta?.client.map((client, index) => (
                        <h5 key={index}>{index == meta?.client.length -1  ? client : client + ", "} </h5>
                    ))}
                </li>
                <li><span>Date:</span> {meta?.date}</li>
                <li><span href={meta?.website}> {meta?.website.length > 1 ? t("References") : t("Reference")}:</span>
                    {meta?.website.map((website, index) => {        
                            return (
                                <h5>
                                    <a key={index} 
                                    target="_blank" 
                                    href={website.link}
                                    > {index == meta?.website.length -1  ? website.id : website.id + ", "} </a>
                                </h5>
                                
                            )    
                    })}
                </li>
                <li>
                    <span>{t("Photos")}:</span>
                    {meta?.photosCredit.map((cred, index) => (
                        <h5 key={index}>{cred}</h5>
                    ))}
                </li>
                <li>
                    <span>Tags:</span>
                    {meta?.services.map((service, index) => (
                        <h5 key={index}>{ index == meta?.services.length -1  ? t(service) : t(service) + ","}</h5>
                    ))}
                </li>
            </ul>
        </div>
    );
};

export default PortfolioDetailsContent;