import React from 'react';
import Logo from "../../logo";
import NavBar from "../../navbar";
import HeaderSocial from "../../HeaderSocial";
import HeaderContainer from "../../../container/header";
import { useTranslation } from "react-i18next";

const Header = ({classes}) => {

    const { i18n } = useTranslation();

    const selectLanguage = (localeKey) => {
        i18n.changeLanguage(localeKey, (err) => {
          if (err)
            return console.log("Something went wrong loading the locale", err);
        });
        localStorage.setItem("i18nLocaleKey", localeKey);
    }

    const currentLanguage = localStorage.getItem("i18nLocaleKey");
    let otherLang = "en";
    if (currentLanguage == "en"){
        otherLang = "fr";
    }

    return (
        <HeaderContainer classes={classes ? classes : null}>
            <Logo/>
            <NavBar/>
            <HeaderSocial/>
            {window.innerWidth > 992 && 
                <div className="header-logo col-lg-1 mt-40 mb-40"> 
                    <h4 onClick={() => selectLanguage(otherLang == "en" ? "en" : "fr")}>{otherLang == "en" ? "EN" : "FR"}</h4> 
                </div>
            }
            
        </HeaderContainer>
    );
};

export default Header;
