import React, {useState, useEffect} from 'react';
import '../../assets/scss/switcher.scss'

const Switcher = () => {

    const [colorVersion, setColorVersion] = useState('color-var-0');

    // Set Default Color On Body
    const body = document.querySelector('body');
    body.classList.add(colorVersion);

    // Switcher Handler
    const customizerHandler = e => {
        e.target.parentNode.classList.toggle('open');
    };


    // Theme Switch Handler
    const themeHandler = e => {
        e.target.classList.toggle('active');
        body.classList.toggle('dark-version');

        const btnTheme = document.querySelector(".btn-theme");

        console.log(btnTheme);
    };

    useEffect(() => {
        // Check Theme Version
        const btnTheme = document.querySelector(".btn-theme");
        if (body.classList.contains('dark-version')) {
            btnTheme.classList.add('active');
        }
    });

    return (

            <button className="btn-theme" onClick={themeHandler}>
                <span className='ball'/>
            </button>

    );
};

export default Switcher;