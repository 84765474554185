import { TypeAnimation } from 'react-type-animation';

const AutoType = (props) => {
  return (
    <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        'coja.cc',
        props.time ? props.time : 60000, // wait 1s before replacing "Mice" with "Hamsters"
        'Jacob',
        props.time ? props.time : 60000,
      ]}
      wrapper="span"
      speed={35}
      cursor={true}
      style={{ fontSize: '2em', display: 'inline-block' }}
      repeat={Infinity}
    />
  );
};


export default AutoType;