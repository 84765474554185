import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const FooterThree = ({position}) => {

    const { t } = useTranslation();

    return (
        <div className={`footer-section section position-${position} pt-30 pb-30`}>
            <Container fluid>
                <Row className={'align-items-center'}>
                    <Col className={'footer-copyright text-center'}>
                        <p>&copy; {new Date().getFullYear()} JACOB LACASSE. {t("rights")}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FooterThree;
