import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import PageContainer from "../../container/CustomPage";
import Service from "../../components/service";
import services from '../../data/service'
import { useTranslation } from "react-i18next";

const TemplateService = () => {

    const { t } = useTranslation(); 

    return (
        <PageContainer classes={'bg-grey'}>
             <Container>
                <Row className={'about-me-section align-items-center'}>
                    <Col md={6} className={'mb-40 mb-xs-30'}>
                        <h1 className="catch-phrase">
                            {t("catchPhrase")}
                        </h1>
                    </Col>
                    <Col md={6} className={'mb-40 mb-xs-30'}>
                        <h4>
                            {t("about_me")}
                        </h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className={'service-bg'}>
                    {services.map(service => (
                        <Col key={service.id} md={6} className={'mb-40 mb-xs-30'}>
                            <Service
                                title={service.title}
                                content={service.content}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </PageContainer>
    );
};

export default TemplateService;