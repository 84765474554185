import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import QuickContact from "../../components/QuickContact";
import Map from "../../components/map";
import { useTranslation } from "react-i18next";

const Contact = () => {

    const { t } = useTranslation();

    return (
        <div className="bg-grey section ">
            <div
                className="section-wrap section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
                <Container>
                    <Row>
                        <Col lg={6} className={'mb-sm-45 mb-xs-45'}>
                            <Row>
                                <Col lg={10}>
                                    <QuickContact
                                        phone={'(581) 982-0424'}
                                        email={'info@coja.cc'}
                                        location={'Montreal (QC), Canada'}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={6}>
                            <Map
                                lat={45.4987}
                                long={-73.5704}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Contact;