import React, {Fragment} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

/*
* @ Component Imported
* */
import AboutPage from "./pages/about";
import ErrorPage from "./pages/404Error";
import ContactPage from "./pages/contact";
import ServicePage from "./pages/service";
import HomeDefault from "./pages/home/HomeDefault";
import PortfolioDetailsPage from "./pages/portfolio/details";
import PortfolioMasonryFiveColumnFullWidthPage from './pages/portfolio/masonry/five-column-fullwidth';
import "./provider/i18n";

const App = () => {
       if (process.env.NODE_ENV !== "development") {
              console.log = () => {};
              console.debug = () => {};
              console.info = () => {};
              console.warn = () => {};
       }


       const randomComponentList = [
                                          HomeDefault,
                                          PortfolioMasonryFiveColumnFullWidthPage,
                                   ]

       const getRandom = (min, max) => {
              return Math.floor(Math.random() * (max - min + 1)) + min;
       }

       const idx = getRandom(0, randomComponentList.length -1);

     return (
              <Fragment>
                     <Router>
                            <Switch>
                                   <Route path={`${process.env.PUBLIC_URL + "/contact"}`}
                                          component={ContactPage}/>
                                   <Route path={`${process.env.PUBLIC_URL + "/about"}`}
                                          component={ServicePage}/>
                                   <Route path={`${process.env.PUBLIC_URL + "/portfolio/:slug"}`}
                                          component={PortfolioDetailsPage}/>
                                   <Route exact path={`${process.env.PUBLIC_URL + "/"}`}
                                          component={HomeDefault}/>
                                   <Route exact component={ErrorPage}/>
                            </Switch>
                     </Router>
              </Fragment>
     );
};

export default App;