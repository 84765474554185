import React from 'react';
import { useTranslation } from "react-i18next";
import socialicons from '../../data/social-networks'

const QuickContact = ({phone, email, location}) => {

    const { t } = useTranslation();

    return (
        <div className="contact-info">
            <h3>{t("contact_title")}</h3>
            <ul>
                <li>
                    <span>{t("phone")}:</span>
                    <a href={`tel:${phone}`}>{phone}</a>
                </li>
                <li>
                    <span>{t("email")}:</span>
                    <a href={`mailto:${email}`}>{email}</a>
                </li>
                <li>
                    <span>{t("location")}:</span>{location}
                </li>
            </ul>
            <br/>
            <div className="side-header-social">
                {socialicons.map(icon => (
                    <a key={icon.id} target="_blank" href={`${icon.url}`}>
                        <i className={`fa fa-${icon.media}`}/>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default QuickContact;